<template>
  <div class="hello">
    <div class="header py-10">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="relative z-50 flex justify-between">
          <div class="flex items-center md:gap-x-12">
            <a href="http://www.liketodo.cn">
              <img class="h-7 w-auto" src="@/assets/images/logo-text.png" />
            </a>
            <div class="hidden md:flex md:gap-x-6">
              <div
                class="inline-block rounded-lg py-1 px-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                @click="goAnchor('#product')"
              >
                Product
              </div>
              <div
                class="inline-block rounded-lg py-1 px-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                @click="goAnchor('#features')"
              >
                Features
              </div>
              <div
                class="inline-block rounded-lg py-1 px-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                @click="goAnchor('#comments')"
              >
                Comments
              </div>
              <div
                class="inline-block rounded-lg py-1 px-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer"
                @click="goAnchor('#about')"
              >
                About
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="page1 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pt-20 pb-20 text-center lg:pt-32 pb-32"
    >
      <h1
        class="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-gray-900 sm:text-7xl"
      >
        Planning 
        <span class="relative whitespace-nowrap text-red-600">
          <svg
            aria-hidden="true"
            viewBox="0 0 418 42"
            class="absolute top-2/3 left-0 h-[0.58em] w-full fill-red-300/70"
            preserveAspectRatio="none"
          >
            <path
              d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"
            ></path>
          </svg>
          <span class="relative">life and work time</span>
        </span>
        <div class='mt-4'>
         for us all.
        </div>
      </h1>
      <p class="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-gray-600">
        Stay on track with ease, with our like to do at your fingertips!
        <br>Anytime, anywhere, unleash your inner creativity for a new day, week, season, or even a new year.
      </p>
      <div class="mt-10 flex justify-center gap-x-6">
        <a
          class="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gray-900 text-white hover:bg-gray-700 hover:text-gray-100 active:bg-gray-800 active:text-gray-300 focus-visible:outline-gray-900"
          target="_blank"
          href="https://liketodo.oss-cn-hangzhou.aliyuncs.com/soft/Liketodo0605.zip"
        >
          Download Windows
        </a>
        <a
          class="group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none ring-gray-200 text-gray-700 hover:text-gray-900 hover:ring-gray-300 active:bg-gray-100 active:text-gray-600 focus-visible:outline-red-600 focus-visible:ring-gray-300"
          target="_blank"
          href="https://apps.apple.com/us/app/like-to-do/id1665423169"
        >
          Download MacOS
        </a>
      </div>
    </div>
    <div class="page2 relative bg-red-500 pt-20 pb-28 sm:py-32" id='product'>
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div class="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
          <h2
            class="font-display text-3xl tracking-tight text-white sm:text-4xl md:text-5xl"
          >
            Everything you need to plan your day.
          </h2>
          <p class="tracking-tight mt-6 text-lg tracking-tight text-red-100">
            Enable users to easily manage tasks, improve efficiency and organizational skills
          </p>
        </div>
        <div
          class="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0"
        >
          <div class="lg:clo-sapn-1"></div>
          <div
            class="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-6"
          >
            <div
              class="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal"
              role="tablist"
              aria-orientation="vertical"
            >
              <div
                class="group relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6 hover:bg-white/10 lg:hover:bg-white/5"
                :class="{
                  'lg:bg-white/10': selectIndex === 0,
                }"
                @click="selectIndex = 0"
              >
                <h3>
                  <button
                    class="font-display text-lg [&amp;:not(:focus-visible)]:focus:outline-none text-red-100 hover:text-white lg:text-white"
                    id="headlessui-tabs-tab-:R2ba9m:"
                    role="tab"
                    type="button"
                    aria-selected="true"
                    tabindex="0"
                    data-headlessui-state="selected"
                    aria-controls="headlessui-tabs-panel-:Rda9m:"
                  >
                    <span
                      class="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl"
                    ></span
                    >Home Page
                  </button>
                </h3>
                <p class="mt-2 hidden text-sm lg:block text-white">
                  Record and plan personal daily activities and tasks; tap on the keyboard to record moments of inspiration and unleash your inner creativity
                </p>
              </div>
              <div
                class="group relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6 hover:bg-white/10 lg:hover:bg-white/5"
                :class="{
                  'lg:bg-white/10': selectIndex === 1,
                }"
                @click="selectIndex = 1"
              >
                <h3>
                  <button
                    class="font-display text-lg [&amp;:not(:focus-visible)]:focus:outline-none text-red-100 hover:text-white lg:text-white"
                    id="headlessui-tabs-tab-:R2ja9m:"
                    role="tab"
                    type="button"
                    aria-selected="false"
                    tabindex="-1"
                    data-headlessui-state=""
                    aria-controls="headlessui-tabs-panel-:Rla9m:"
                  >
                    <span
                      class="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl"
                    ></span
                    >Completed tasks
                  </button>
                </h3>
                <p
                  class="mt-2 hidden text-sm lg:block text-red-100 group-hover:text-white"
                >
                Showing all historical tasks completed by date, plus the time it took you to complete them
                </p>
              </div>
              <div
                class="group relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6 hover:bg-white/10 lg:hover:bg-white/5"
                :class="{
                  'lg:bg-white/10': selectIndex === 2,
                }"
                @click="selectIndex = 2"
              >
                <h3>
                  <button
                    class="font-display text-lg [&amp;:not(:focus-visible)]:focus:outline-none text-red-100 hover:text-white lg:text-white"
                    id="headlessui-tabs-tab-:R2ra9m:"
                    role="tab"
                    type="button"
                    aria-selected="false"
                    tabindex="-1"
                    data-headlessui-state=""
                    aria-controls="headlessui-tabs-panel-:Rta9m:"
                  >
                    <span
                      class="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl"
                    ></span
                    >Timer
                  </button>
                </h3>
                <p
                  class="mt-2 hidden text-sm lg:block text-red-100 group-hover:text-white"
                >
                  Ensure you never miss important tasks and activities with the ability to set countdown reminders and positive timers.
                </p>
              </div>
              <div
                class="group relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6 hover:bg-white/10 lg:hover:bg-white/5"
                :class="{
                  'lg:bg-white/10': selectIndex === 3,
                }"
                @click="selectIndex = 3"
              >
                <h3>
                  <button
                    class="font-display text-lg [&amp;:not(:focus-visible)]:focus:outline-none text-red-100 hover:text-white lg:text-white"
                    id="headlessui-tabs-tab-:R33a9m:"
                    role="tab"
                    type="button"
                    aria-selected="false"
                    tabindex="-1"
                    data-headlessui-state=""
                    aria-controls="headlessui-tabs-panel-:R15a9m:"
                  >
                    <span
                      class="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl"
                    ></span
                    >All types of soothing background music
                  </button>
                </h3>
                <p
                  class="mt-2 hidden text-sm lg:block text-red-100 group-hover:text-white"
                >
                The sound of a fire in a wooden house on a snowy day, the sound of birdsong in a tropical rainforest, and the sound of raindrops falling in an ancient Japanese temple...
                </p>
              </div>
            </div>
          </div>
          <div class="lg:col-span-4">
            <div
              id="headlessui-tabs-panel-:Rda9m:"
              role="tabpanel"
              tabindex="0"
              data-headlessui-state="selected"
              aria-labelledby="headlessui-tabs-tab-:R2ba9m:"
              style=""
            >
              <div class="relative sm:px-6 lg:hidden">
                <div
                  class="absolute -inset-x-4 top-[-6.5rem] bottom-[-4.25rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl"
                ></div>
                <p
                  class="relative mx-auto max-w-2xl text-base text-white sm:text-center"
                >
                  Keep track of everyone's salaries and whether or not they've
                  been paid. Direct deposit not supported.
                </p>
              </div>
              <div
                class="mt-20 overflow-hidden rounded-xl h-[32rem] shadow-red-900/20 flex justify-center lg:mt-0"
              >
                <img
                  v-if="selectIndex === 0"
                  src="@/assets/images/index.png"
                  class="h-[32rem] iproduct"
                />
                <img
                  v-if="selectIndex === 1"
                  src="@/assets/images/complete.png"
                  class="h-[32rem] iproduct"
                />
                <img
                  v-if="selectIndex === 2"
                  src="@/assets/images/timer.png"
                  class="h-[32rem] iproduct"
                />
                <img
                  v-if="selectIndex === 3"
                  src="@/assets/images/music.png"
                  class="h-[32rem] iproduct"
                />
              </div>
            </div>
            <div
              id="headlessui-tabs-panel-:Rla9m:"
              role="tabpanel"
              tabindex="-1"
              style="display: none"
              data-headlessui-state=""
              aria-labelledby="headlessui-tabs-tab-:R2ja9m:"
              hidden=""
            >
              <div class="relative sm:px-6 lg:hidden">
                <div
                  class="absolute -inset-x-4 top-[-6.5rem] bottom-[-4.25rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl"
                ></div>
                <p
                  class="relative mx-auto max-w-2xl text-base text-white sm:text-center"
                >
                  All of your receipts organized into one place, as long as you
                  don't mind typing in the data by hand.
                </p>
              </div>
              <div
                class="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-red-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]"
              >
              </div>
            </div>
            <div
              id="headlessui-tabs-panel-:Rta9m:"
              role="tabpanel"
              tabindex="-1"
              style="display: none"
              data-headlessui-state=""
              aria-labelledby="headlessui-tabs-tab-:R2ra9m:"
              hidden=""
            >
              <div class="relative sm:px-6 lg:hidden">
                <div
                  class="absolute -inset-x-4 top-[-6.5rem] bottom-[-4.25rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl"
                ></div>
                <p
                  class="relative mx-auto max-w-2xl text-base text-white sm:text-center"
                >
                  We only sell our software to companies who don't deal with VAT
                  at all, so technically we do all the VAT stuff they need.
                </p>
              </div>
              <div
                class="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-red-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]"
              >
              </div>
            </div>
            <div
              id="headlessui-tabs-panel-:R15a9m:"
              role="tabpanel"
              tabindex="-1"
              style="display: none"
              data-headlessui-state=""
              aria-labelledby="headlessui-tabs-tab-:R33a9m:"
              hidden=""
            >
              <div class="relative sm:px-6 lg:hidden">
                <div
                  class="absolute -inset-x-4 top-[-6.5rem] bottom-[-4.25rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl"
                ></div>
                <p
                  class="relative mx-auto max-w-2xl text-base text-white sm:text-center"
                >
                  Easily export your data into an Excel spreadsheet where you
                  can do whatever the hell you want with it.
                </p>
              </div>
              <div
                class="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-red-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white py-24 sm:py-32" id='features'>
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:text-center">
          <h2 class="text-base font-semibold leading-7 text-red-600">
            Some information
          </h2>
          <p
            class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
          >
            Plan your schedule with everything
          </p>
          <p class="mt-6 text-lg leading-8 text-gray-600">
            Whether it's a work schedule, a memo reminder, a shopping list, or a travel itinerary, you can record like to do and plan in an organized way.
          </p>
        </div>
        <div class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl
            class="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16"
          >
            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div
                  class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-600"
                >
                  <svg
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                    />
                  </svg>
                </div>
                Time planning
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                 Helps users plan their time.  Users can set reminders to make sure they don't miss anything important.
              </dd>
            </div>

            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div
                  class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-600"
                >
                  <svg
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                </div>
                SSL certificates
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                Provides a secure and encrypted connection , ensuring that sensitive information is protected from interception and unauthorized access. 
              </dd>
            </div>

            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div
                  class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-600"
                >
                  <svg
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </div>
                Task Management
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                 Allows users to better manage menial tasks, record start and finish dates, and set countdowns or timers. 
              </dd>
            </div>

            <div class="relative pl-16">
              <dt class="text-base font-semibold leading-7 text-gray-900">
                <div
                  class="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-red-600"
                >
                  <svg
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33"
                    />
                  </svg>
                </div>
                Cloud
              </dt>
              <dd class="mt-2 text-base leading-7 text-gray-600">
                Backs up users' schedules and tasks to the cloud. Users can change their login device at any time to access it when they need it.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <section
      id="comments"
      aria-label="What our customers are saying"
      class="bg-slate-50 py-20 sm:py-32"
    >
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-2xl md:text-center">
          <h2
            class="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl"
          >
            Loved by people all over the world.
          </h2>
          <p class="mt-4 text-lg tracking-tight text-slate-700">
            Our software is so convenient and efficient that people can't help but fall in love with it. Its vivid pages show your daily life
          </p>
        </div>
        <ul
          role="list"
          class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          <li>
            <ul role="list" class="flex flex-col gap-y-6 sm:gap-y-8">
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      I like the schedule feature, it helps me organize my schedule and tasks easily. I can add, edit, delete, and view my schedule at any time.
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        Sheryl Berge
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        Editor of a fashion magazine
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-1.jpg"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      I really love the white noise background music feature, it helps me stay focused when working and studying. There are different options for white noise.
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        Amy Hahn
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        Accounting Supervisor
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-4.jpg"
                        width="56"
                        height="56"
                        decoding="async"
                        data-nimg="1"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>
          <li>
            <ul role="list" class="flex flex-col gap-y-6 sm:gap-y-8">
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                       like to do is very intuitive and easy to use. I like interface design and layout, which makes it easy for me to find the information I need, and when I work from home.
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        Leland Kiehn
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        Social Marketing Manager
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-2.jpg"
                        width="56"
                        height="56"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      The best part about like to do. It helps me categorize my tasks and meetings by time and date and gives me a clearer picture of my schedule.
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        Erin Powlowski
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        Interaction Designer
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-5.jpg"
                        width="56"
                        height="56"
                        decoding="async"
                        data-nimg="1"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>
          <li>
            <ul role="list" class="flex flex-col gap-y-6 sm:gap-y-8">
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      I really like the reminder feature of like to do. It helps me remember my important tasks and meetings so that I don't miss anything important.
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        Peter Renolds
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        Junior college student
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avatar-3.jpg"
                        width="56"
                        height="56"
                        decoding="async"
                        data-nimg="1"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
              <li>
                <figure
                  class="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10"
                >
                  <svg
                    aria-hidden="true"
                    width="105"
                    height="78"
                    class="absolute top-6 left-6 fill-slate-100"
                  >
                    <path
                      d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z"
                    ></path>
                  </svg>
                  <blockquote class="relative">
                    <p class="text-lg tracking-tight text-slate-900">
                      Very handy. I can sync my schedule on my computer, phone, and tablet, and I can check my schedule anytime, no matter where I am
                    </p>
                  </blockquote>
                  <figcaption
                    class="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6"
                  >
                    <div>
                      <div class="font-display text-base text-slate-900">
                        Amy Hahn
                      </div>
                      <div class="mt-1 text-sm text-slate-500">
                        In-service physician
                      </div>
                    </div>
                    <div class="overflow-hidden rounded-full bg-slate-50">
                      <img
                        src="@/assets/images/avater06.png"
                        width="56"
                        height="56"
                        decoding="async"
                        data-nimg="1"
                        class="h-14 w-14 object-cover"
                        loading="lazy"
                        style="color: transparent"
                      />
                    </div>
                  </figcaption>
                </figure>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
    <footer class="bg-slate-50">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="py-16">
          <div class="flex justify-center">
            <img class="h-7 w-auto" src="@/assets/images/logo-text.png" />
          </div>
          <nav class="mt-10 text-sm" aria-label="quick links">
            <div class="-my-1 flex justify-center gap-x-6">
              <a
                class="cursor-pointer inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
                @click="goAnchor('#product')"
                >Product</a
              ><a
                class="cursor-pointer inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
                @click="goAnchor('#features')"
                >Features</a
              ><a
                class="cursor-pointer inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
                @click="goAnchor('#comments')"
                >Comments</a
              >
            </div>
          </nav>
        </div>
        <div
          class="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between"
          id='about'
        >
          <div class="flex text-sm text-slate-500 sm:mt-0">
            <a href='https://beian.miit.gov.cn/' target="_blank">
              浙ICP备14037565号-18
            </a>
          </div>
          <p class="mt-6 text-sm text-slate-500 sm:mt-0">
            Copyright ©
            2023
            Like To Do. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      selectIndex: 0,
    };
  },
  methods: {
    goAnchor(selector) {/*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth"
      });
    },
  },
};
</script>

<style lang='scss' scoped>
body{
  font-family: "SF Pro SC","SF Pro Text","SF Pro Icons","PingFang SC","Helvetica Neue","Helvetica","Arial",sans-serif !important;
}
</style>
